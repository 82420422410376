import { GiftOutlined, TrophyOutlined } from '@ant-design/icons';
import { Steps, Button, message, Spin } from 'antd';
import './styles.scss';
import { snappy } from 'src/_shared';
import { useState, useEffect } from 'react';

const RewardsProgressIndicator = ({ tiers, currentUser }) => {
	const [giftsByCampaignTierId, setGiftsByCampaignTierId] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getCampaigns();
	}, []);

	const handleClaimGift = async (tier) => {
		try {
			const response = await snappy({
				type: 'createGifts',
				userId: currentUser.id,
				sendingMethod: 'link',
				recipients: [currentUser.id],
				campaignTierId: tier.id,
			});

			if (response && response.length > 0) {
				const result = response[0];
				if (result.giftId && result.giftDetails && result.giftDetails.link) {
					await getCampaigns();
					window.open(result.giftDetails.link, '_blank');
				} else {
					message.error(result.errorMessage || 'Error claiming gift.');
					console.error('Error claiming gift:', result.errorMessage);
				}
			} else {
				message.error('Unexpected response from the server.');
				console.error('Unexpected response:', response);
			}
		} catch (error) {
			console.error('Error claiming gift', error);
			message.error('An error occurred while claiming the gift.');
		}
	};

	const getCampaigns = async () => {
		setIsLoading(true);
		try {
			const response = await snappy({
				type: 'getCampaigns',
				userId: currentUser.id,
			});
			processCampaigns(response || []);
		} catch (error) {
			console.error('Error getCampaigns:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const processCampaigns = (campaignsData) => {
		const giftsMap = {};
		campaignsData.forEach((campaign) => {
			campaign.tiers.forEach((tier) => {
				if (tier.gift) {
					giftsMap[tier.id] = tier.gift;
				}
			});
		});
		setGiftsByCampaignTierId(giftsMap);
	};

	if (isLoading) {
		return (
			<div style={{ textAlign: 'center', padding: '24px' }}>
				<Spin size="large" />
			</div>
		);
	}

	const items = tiers.map((tier) => {
		const isClaimable = tier.userTotalPoints >= tier.pointsRequired;
		const gift = giftsByCampaignTierId[tier.id];

		let button;
		let icon;
		let status;
		let isClaimed = false;

		if (gift) {
			const giftStatus = gift.giftDetails.status;
			if (giftStatus !== 'claimed') {
				button = (
					<Button
						size="small"
						type="link"
						onClick={() => window.open(gift.giftDetails.link, '_blank')}
					>
						<strong>Claim Reward</strong>
					</Button>
				);
				icon = <GiftOutlined />;
				status = 'finish';
			} else {
				button = <strong>Claimed</strong>;
				icon = <GiftOutlined />;
				status = 'finish';
			}
			isClaimed = true;
		} else if (isClaimable) {
			button = (
				<Button size="small" type="link" onClick={() => handleClaimGift(tier)}>
					<strong>Claim Reward</strong>
				</Button>
			);
			icon = <TrophyOutlined />;
			status = 'finish';
		} else {
			button = <strong>{tier.pointsRequired} points</strong>;
			icon = <TrophyOutlined />;
			status = 'wait';
		}

		return {
			title: <p className="rewards-progress-reward-level">{tier.tierName}</p>,
			description: (
				<>
					<p className="rewards-progress-reward-description">
						{tier.tierDescription}
					</p>
					<p
						className="rewards-progress-reward-points"
						style={{ color: isClaimable ? 'green' : 'gray' }}
					>
						{button}
					</p>
				</>
			),
			icon: (
				<div
					className={`icon-wrapper ${isClaimable ? 'claimed' : ''}`}
					style={{
						backgroundColor: isClaimable ? '#f650a9' : 'lightgrey',
					}}
				>
					{icon}
				</div>
			),
			status,
		};
	});

	const itemIsClaimable = items.map((item, index) => {
		return {
			index,
			isClaimable: tiers[index].userTotalPoints >= tiers[index].pointsRequired,
		};
	});

	for (let i = 1; i < items.length; i++) {
		if (itemIsClaimable[i - 1].isClaimable && itemIsClaimable[i].isClaimable) {
			items[i - 1].className = 'tail-pink';
		}
	}

	const currentStepIndex = tiers.findIndex(
		(tier) => tier.userTotalPoints < tier.pointsRequired
	);

	return (
		<>
			<Steps
				current={currentStepIndex === -1 ? tiers.length : currentStepIndex}
				labelPlacement="vertical"
				items={items}
			/>
		</>
	);
};

export default RewardsProgressIndicator;
