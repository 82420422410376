import React, { useMemo } from 'react';
import { MAPPED_BADGE_TYPE } from './rewardsConstants.js'
import PeerToPeerBadge from './PeerToPeerBadge/PeerToPeerBadge.jsx';
import AutomateBadge from './Automate/AutomateBadge.jsx';

const CoreBadge = (props) => {
    const { label, companyName } = props;
    const breakWords = useMemo(() => {
        if (label.length > 15) {
            const words = label.split(' ');
            let line1 = '';
            let line2 = '';
            for (const word of words) {
                if (line1.length + word.length + 1 <= 15) {
                    line1 += word + ' ';
                } else {
                    line2 += word + ' ';
                }
            }
            return [line1, line2].filter(line => line.trim().length > 0);
        } else {
            return [label];
        }
    }, [label]);

    switch (props.badgeType) {
        case MAPPED_BADGE_TYPE.PEERTOPEER:
        case MAPPED_BADGE_TYPE.NOMINATED:
            return <PeerToPeerBadge breakWords={breakWords} {...props} />
        case MAPPED_BADGE_TYPE.AUTOMATED:
            return <AutomateBadge companyName={companyName} breakWords={breakWords} {...props} />
        default:
            return <></>
    }
}

export default CoreBadge;
