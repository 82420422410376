import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { message } from 'antd';
import AddReward from '../../presentation/addReward/AddReward';
import { CoreBadge } from '../../../RnRCommonComponent';
import { useManageRewardsService } from '../../services/manageRewards.service.jsx';
// import * as htmlToImage from 'html-to-image';

const AddRewardContainer = (props) => {
	const { isModalOpen, handleCloseModal, setShouldRefresh } = props;

	const [badgeDetailsObject, setBadgeDetailsObject] = useState({});
	const [badgeErrorObject, setBadgeErrorObject] = useState();
	const [iconsList, setIconsList] = useState([]);
	const [automationRules, setAutomationRule] = useState([]);
	const [badgeTypeList, setBadgeTypeList] = useState();
	const [isLoaderVisible, setIsLoaderVisible] = useState(false);
	const [levelList, setLevelList] = useState();
	const [payTypeList, setPayTypeList] = useState();
	const [automation, setAutomation] = useState();
	const manageRewardService = useManageRewardsService();

	useEffect(() => {
		getBadgeTypesList();
		getIconsList();
		getLevelList();
		getPayTypeList();
		setBadgeErrorObject(null);
		getAutomationRules();
	}, []);

	const getLevelList = () => {
		manageRewardService
			.getEmployeeLevels()
			.then((response) => {
				if (response?.code === 200) {
					if (response?.data) {
						setLevelList(response?.data);
					}
				} else {
					message.error('Failed to fetch badge type list! Try again later.');
				}
			})
			.catch((error) => {
				message.error('Failed to fetch badge type list! Try again later.');
			});
	};

	const getPayTypeList = () => {
		manageRewardService
			.getEmployeePayType()
			.then((response) => {
				if (response?.code === 200) {
					if (response?.data) {
						setPayTypeList(response?.data);
					}
				} else {
					message.error('Failed to fetch badge type list! Try again later.');
				}
			})
			.catch((error) => {
				message.error('Failed to fetch badge type list! Try again later.');
			});
	};

	const getBadgeTypesList = () => {
		manageRewardService
			.getBadgeTypes()
			.then((getBadgeTypesListRes) => {
				if (getBadgeTypesListRes?.code === 200) {
					if (getBadgeTypesListRes?.data) {
						setBadgeTypeList(getBadgeTypesListRes?.data);
					}
				} else {
					message.error('Failed to fetch badge type list! Try again later.');
				}
			})
			.catch((error) => {
				message.error('Failed to fetch badge type list! Try again later.');
			});
	};

	const getIconsList = () => {
		manageRewardService
			.getIcons()
			.then((iconsListResponse) => {
				if (iconsListResponse?.code === 200) {
					if (iconsListResponse?.data) {
						setIconsList(iconsListResponse?.data);
					}
				} else {
					message.error('Failed to fetch icons list! Try again later.');
				}
			})
			.catch((error) => {
				message.error('Failed to fetch icons list! Try again later.');
			});
	};

	const getAutomationRules = () => {
		manageRewardService
			.getAutomationRule()
			.then((automationRuleListResponse) => {
				if (automationRuleListResponse?.code === 200) {
					if (automationRuleListResponse?.data) {
						setAutomationRule(automationRuleListResponse?.data);
					}
				} else {
					message.error(
						'Failed to fetch Automation Rules list! Try again later.'
					);
				}
			})
			.catch(() => {
				message.error(
					'Failed to fetch Automation Rules list! Try again later.'
				);
			});
	};

	const handleCreateBadge = async () => {
		const requiredFields = ['name', 'label', 'description', 'color'];
		let hasEmptyField = false;
		let errorObject = badgeErrorObject && badgeErrorObject;

		for (const field of requiredFields) {
			if (
				!badgeDetailsObject[field] ||
				badgeDetailsObject[field]?.trim() === ''
			) {
				errorObject = {
					...errorObject,
					[field]: true,
				};

				hasEmptyField = true;
			}
		}

		const pointsValue = parseInt(badgeDetailsObject.points);
		if (
			badgeDetailsObject.points === '' ||
			isNaN(badgeDetailsObject.points) ||
			pointsValue < 0 ||
			pointsValue > 100000
		) {
			errorObject = {
				...errorObject,
				points: true,
			};
			hasEmptyField = true;
		}

		setBadgeErrorObject(errorObject);

		if (hasEmptyField) {
			return;
		}

		const badgeElement = document.createElement('div');
		document.body.appendChild(badgeElement);

		badgeElement.style.width = '412px';
		badgeElement.style.height = '412px';
		badgeElement.style.display = 'flex';
		badgeElement.style.justifyContent = 'center';
		badgeElement.style.alignItems = 'center';

		console.log(badgeDetailsObject.color);

		ReactDOM.render(
			<CoreBadge
				badgeType={badgeDetailsObject.badgeTypeCode}
				badgeIcon={badgeDetailsObject.iconCode}
				label={badgeDetailsObject.label}
				color={badgeDetailsObject.color}
			/>,
			badgeElement
		);

		try {
			// const dataUrl = await htmlToImage.toPng(badgeElement);
			// const link = document.createElement('a');
			// link.href = dataUrl;
			// link.download = 'badge.png';
			// document.body.appendChild(link);
			// link.click();
			// document.body.removeChild(link);

			// const pngBase64 = dataUrl.split(',')[1];
			setIsLoaderVisible(true);

			const stringBadge = ReactDOMServer.renderToString(
				<svg xmlns="http://www.w3.org/2000/svg"
						 viewBox="0 0 512 512">
					<path
						d="M128 32l32 0c17.7 0 32 14.3 32 32l0 32L96 96l0-32c0-17.7 14.3-32 32-32zm64 96l0 320c0 17.7-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32l0-59.1c0-34.6 9.4-68.6 27.2-98.3C40.9 267.8 49.7 242.4 53 216L60.5 156c2-16 15.6-28 31.8-28l99.8 0zm227.8 0c16.1 0 29.8 12 31.8 28L459 216c3.3 26.4 12.1 51.8 25.8 74.6c17.8 29.7 27.2 63.7 27.2 98.3l0 59.1c0 17.7-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32l0-320 99.8 0zM320 64c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 32-96 0 0-32zm-32 64l0 160-64 0 0-160 64 0z" />
				</svg>
			);

			// console.log(stringBadge);
			// return;
			setIsLoaderVisible(true);

			const base64Encoded = btoa(stringBadge);

			manageRewardService
				.createBadge({
					...badgeDetailsObject,
					points: badgeDetailsObject?.points || 0,
					automationRule: automation,
					image: base64Encoded,
				})
				.then((badgesTypeCreateRes) => {
					if (badgesTypeCreateRes?.code === 201) {
						message.success('Badge created successfully');
						setShouldRefresh(true);
						handleCloseModal();
					} else {
						message.error(
							badgesTypeCreateRes?.message ||
							'Failed to create badge! Try again later.',
						);
					}
					setIsLoaderVisible(false);
				})
				.catch((error) => {
					setIsLoaderVisible(false);
					message.error(
						error?.message || 'Failed to create badge! Try again later.'
					);
				});

		} catch (error) {
			console.error('Failed to create badge image:', error);
			message.error('Failed to create badge image! Try again later.');
		} finally {
			document.body.removeChild(badgeElement);
		}
	};

	return (
		<div>
			<AddReward
				iconsList={iconsList}
				badgeTypeList={badgeTypeList}
				isModalOpen={isModalOpen}
				handleCloseModal={handleCloseModal}
				handleCreateBadge={handleCreateBadge}
				badgeDetailsObject={badgeDetailsObject}
				setBadgeDetailsObject={setBadgeDetailsObject}
				badgeErrorObject={badgeErrorObject}
				setBadgeErrorObject={setBadgeErrorObject}
				isLoaderVisible={isLoaderVisible}
				levelList={levelList}
				payTypeList={payTypeList}
				automation={automation}
				setAutomation={setAutomation}
				automationRules={automationRules}
			/>
		</div>
	);
};

export default AddRewardContainer;
