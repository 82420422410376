import React,
{ useEffect, useState } from 'react';
import ReactDOMServer from "react-dom/server";
import { message } from 'antd';
import EditReward from '../../presentation/editReward/EditReward.jsx'
import { CoreBadge } from '../../../RnRCommonComponent'
import { useManageRewardsService } from '../../services/manageRewards.service.jsx';

const EditRewardContainer = (props) => {

  const { isEditModalOpen, handleCloseModal, selectedBadge, setShouldRefresh } = props;

  const [badgeDetailsObject, setBadgeDetailsObject] = useState({});
  const [badgeErrorObject, setBadgeErrorObject] = useState();
  const [iconsList, setIconsList] = useState([]);
  const [badgeTypeList, setBadgeTypeList] = useState();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [levelList, setLevelList] = useState();
  const [payTypeList, setPayTypeList] = useState();
  const [automationRules, setAutomationRule] = useState([]);

  const manageRewardService = useManageRewardsService();

  useEffect(() => {
    getBadgeTypesList();
    getIconsList();
    getLevelList();
    getPayTypeList();
    getAutomationRules();
  }, [])

  useEffect(() => {
    if (selectedBadge) {
      getBadgeDetails();
    }
  }, [selectedBadge]);

  const getBadgeDetails = () => {
    setIsLoaderVisible(true);
    manageRewardService.getBadgeDetails(selectedBadge?.id).then((response) => {
      setIsLoaderVisible(false)
      if (response?.code === 200) {
        if (response?.data) {
          setBadgeDetailsObject(response.data);
        }
      } else {
        message.error('Failed to fetch badge details! Try again later.');
      }
    }).catch((error) => {
      setIsLoaderVisible(false)
      console.log(error);
      message.error('Failed to fetch badge details! Try again later.');
    });
  }

  const getAutomationRules = () => {
    manageRewardService.getAutomationRule().then((automationRuleListResponse) => {
      if (automationRuleListResponse?.code === 200) {
        if (automationRuleListResponse?.data) {
          setAutomationRule(automationRuleListResponse?.data);
        }
      } else {
        message.error('Failed to fetch Automation Rules list! Try again later.');
      }
    }).catch(() => {
      message.error('Failed to fetch Automation Rules list! Try again later.');
    });
  };

  const getBadgeTypesList = () => {
    setIsLoaderVisible(true);
    manageRewardService.getBadgeTypes().then((getBadgeTypesListRes) => {
      if (getBadgeTypesListRes?.code === 200) {
        if (getBadgeTypesListRes?.data) {
          setBadgeTypeList(getBadgeTypesListRes?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      console.log(error);
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getIconsList = () => {
    setIsLoaderVisible(true);
    manageRewardService.getIcons().then((iconsListResponse) => {
      if (iconsListResponse?.code === 200) {
        if (iconsListResponse?.data) {
          setIconsList(iconsListResponse?.data);
        }
      } else {
        message.error('Failed to fetch icons list! Try again later.');
      }
    }).catch((error) => {
      console.log(error);
      message.error('Failed to fetch icons list! Try again later.');
    });
  };

  const getLevelList = () => {
    manageRewardService.getEmployeeLevels().then((response) => {
      if (response?.code === 200) {
        if (response?.data) {
          setLevelList(response?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getPayTypeList = () => {
    manageRewardService.getEmployeePayType().then((response) => {
      if (response?.code === 200) {
        if (response?.data) {
          setPayTypeList(response?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const handleUpdateBadge = async () => {
    const requiredFields = [
      'name',
      'label',
      'description',
      'color'
    ];
    let hasEmptyField = false;
    let errorObject = badgeErrorObject && badgeErrorObject;

    for (const field of requiredFields) {
      if (
        !badgeDetailsObject[field] ||
        badgeDetailsObject[field]?.toString().trim() === ''
      ) {
        errorObject = {
          ...errorObject,
          [field]: true,
        };

        hasEmptyField = true;
      }
    }
    //Validation of points 
    const pointsValue = parseInt(badgeDetailsObject.points);
    if (badgeDetailsObject.points === '' || isNaN(badgeDetailsObject.points) || pointsValue < 0 || pointsValue > 9999) {
      errorObject = {
        ...errorObject,
        points: true,
      };
      hasEmptyField = true;
    }

    setBadgeErrorObject(errorObject);

    if (hasEmptyField) {
      return;
    }

    const stringBadge = ReactDOMServer.renderToString(<svg xmlns="http://www.w3.org/2000/svg"
                                                           viewBox="0 0 512 512">
      <path
        d="M128 32l32 0c17.7 0 32 14.3 32 32l0 32L96 96l0-32c0-17.7 14.3-32 32-32zm64 96l0 320c0 17.7-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32l0-59.1c0-34.6 9.4-68.6 27.2-98.3C40.9 267.8 49.7 242.4 53 216L60.5 156c2-16 15.6-28 31.8-28l99.8 0zm227.8 0c16.1 0 29.8 12 31.8 28L459 216c3.3 26.4 12.1 51.8 25.8 74.6c17.8 29.7 27.2 63.7 27.2 98.3l0 59.1c0 17.7-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32l0-320 99.8 0zM320 64c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 32-96 0 0-32zm-32 64l0 160-64 0 0-160 64 0z" />
    </svg>);

    setIsLoaderVisible(true);

    const base64Encoded = btoa(stringBadge);
    const requestBody = {
      name: badgeDetailsObject.name,
      label: badgeDetailsObject.label,
      automationRule: badgeDetailsObject.automationRule,
      description: badgeDetailsObject.description,
      badgeTypeCode: badgeDetailsObject.badgeTypeCode,
      color: badgeDetailsObject.color,
      image: base64Encoded,
      visibility: badgeDetailsObject.visibility,
      iconCode: badgeDetailsObject.iconCode,
      bonusPoints: badgeDetailsObject.bonusPoints,
      rewardBonus: badgeDetailsObject.rewardBonus,
      points: badgeDetailsObject.points || 0,
      isPreApproved: badgeDetailsObject.isPreApproved,
      externalWorkFlowId: badgeDetailsObject.externalWorkFlowId || "",
      physicalAwardId: badgeDetailsObject.physicalAwardId || "",
      recipientLevelId: badgeDetailsObject.recipientLevelId,
      assignmentLevelId: badgeDetailsObject.assignmentLevelId,
      isAssignmentSSORequired: badgeDetailsObject.isAssignmentSSORequired,
      isRecipientSSORequired: badgeDetailsObject.isRecipientSSORequired,
      recipientPayTypeId: badgeDetailsObject.recipientPayTypeId,
      rewardMaxBonus: badgeDetailsObject.rewardMaxBonus,
    }

    manageRewardService.updateBadge(badgeDetailsObject.id, requestBody).then((response) => {
      if (response.code === 200) {
        message.success(response.message || 'Badge updated successfully');
        setShouldRefresh(true)
        handleCloseModal();
        setBadgeDetailsObject(null);
      } else {
        message.error(response.message || 'Failed to update badge! Try again later.');
      }
      setIsLoaderVisible(false);
    }).catch((error) => {
      setIsLoaderVisible(false);
      message.error(error?.response?.message || 'Failed to update badge! Try again later.');
    });
  };

  return <div>
    <EditReward
      iconsList={iconsList}
      badgeTypeList={badgeTypeList}
      isModalOpen={isEditModalOpen}
      handleCloseModal={handleCloseModal}
      handleUpdateBadge={handleUpdateBadge}
      badgeDetailsObject={badgeDetailsObject}
      setBadgeDetailsObject={setBadgeDetailsObject}
      badgeErrorObject={badgeErrorObject}
      setBadgeErrorObject={setBadgeErrorObject}
      isLoaderVisible={isLoaderVisible}
      levelList={levelList}
      automationRules={automationRules}
      payTypeList={payTypeList}
    />
  </div>;
};

export default EditRewardContainer;
