import BadgeIcon from './BadgeIcon';
import { useState, useRef, useEffect } from 'react';
import './NeoBadge.css';

const NeoBadge = ({ shape = 'hexagon', icon = "ANCHOR", colors = {gradient: "linear-gradient(to bottom right, #6366f1, #0ea5e9, #10b981)", icon: "#ffffff", text: "#ffffff" }, text }) => {
	const [isHovered, setIsHovered] = useState(false);
	const badgeRef = useRef(null);

	const getBadgeShape = () => {
		switch (shape) {
			case 'circle':
				return 'badge-circle';
			case 'hexagon':
				return 'badge-hexagon';
			case 'shield':
				return 'badge-shield';
			default:
				return 'badge-square';
		}
	};

	useEffect(() => {
		const badge = badgeRef.current;
		const handleMouseMove = (e) => {
			if (!badge) return;
			const { left, top, width, height } = badge.getBoundingClientRect();
			const x = (e.clientX - left) / width - 0.5;
			const y = (e.clientY - top) / height - 0.5;
			badge.style.transform = `
        perspective(1000px)
        rotateY(${x * 10}deg)
        rotateX(${y * -10}deg)
        translateZ(20px)
      `;
		};

		if (isHovered) {
			document.addEventListener('mousemove', handleMouseMove);
		}

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
		};
	}, [isHovered]);

	return (
		<>
			<div
				ref={badgeRef}
				className={`badge`}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => {
					setIsHovered(false);
					badgeRef.current.style.transform = '';
				}}
			>
				<div style={{ background: colors.gradient }}
						 className={`badge-inner  ${isHovered ? 'hovered' : ''} ${getBadgeShape()}`}>
					<div className="badge-content">
						<div className="icon-circle">
							<BadgeIcon iconName={icon} className="badge-icon" color={colors.icon} />
						</div>
					</div>
					<div className="shine-effect"></div>
				</div>
				<div className="badge-ribbon">
					<span className="badge-text" style={{ color: colors.text }}>{text}</span>
				</div>
			</div>
		</>
	);
};

export default NeoBadge;
