import { useEffect, useState } from "react";
import NeoBadge from '../BadgeIcon/NeoBadge';


const P2PBadge = ({badgeIcon, color, breakWords, shape = 'hexagon'}) => {
  const [badgeColors, setBadgeColors] = useState(badgeIcon);

  useEffect(() => {
    const generateGradient = (baseColor) => {
      // Function to convert hex color to RGB
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex?.slice(1), 16);
        return {
          r: (bigint >> 16) & 255,
          g: (bigint >> 8) & 255,
          b: bigint & 255,
        };
      };

      // Function to darken RGB by a certain percentage
      const darken = (rgb, percentage) => ({
        r: Math.max(Math.floor(rgb.r * (1 - percentage)), 0),
        g: Math.max(Math.floor(rgb.g * (1 - percentage)), 0),
        b: Math.max(Math.floor(rgb.b * (1 - percentage)), 0),
      });

      // Function to convert RGB back to hex
      const rgbToHex = ({ r, g, b }) =>
        `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16)?.slice(1)}`;

      const rgbColor = hexToRgb(baseColor);

      // Generate darker shades
      const midColor = darken(rgbColor, 0.2); // 50% darker for middle stop
      const endColor = darken(rgbColor, 0.4); // 75% darker for end stop

      return {
        start: baseColor,
        mid: rgbToHex(midColor),
        end: rgbToHex(endColor),
        text: '#ffffff',
      };
    };

    const colorObjects = generateGradient(color);
    setBadgeColors(colorObjects);

  }, [color]);

  return (
    <NeoBadge
      shape={shape}
      icon={badgeIcon ?? "ANCHOR"}
      colors={{
        gradient: `linear-gradient(to bottom right, ${badgeColors?.start ?? '#6366f1'}, ${badgeColors?.mid ?? '#0ea5e9'}, ${badgeColors?.end ?? '#10b981'}`,
        //gradient: `linear-gradient(45deg,${badgeColors?.start ?? "#355C7D"},${badgeColors?.end ?? "#68B3AF"})`,
        icon: `${badgeColors?.start ?? '#6366f1'}`,
        text: `${badgeColors?.text ?? '#0ea5e9'}`
      }}
      text={breakWords ?? "Achievement Unlocked"}
    />
  );
};

export default P2PBadge;