import React from 'react';

import CustomCards from './CutomCards.jsx';

import { ReactComponent as PointsToSendIcon } from './../../../assets/svg/pointstosend.svg';
import { ReactComponent as RecipientIcon } from './../../../assets/svg/recipientOne.svg';
import { ReactComponent as RewardIcon } from './../../../assets/svg/rewardOne.svg';

import './style.scss';

const RewardPointsAndBonus = (props) => {
	const { rewardPointsBonus } = props;
	return (
		<div className="rewards-points d-card height-equal">
			<div className="d-card-head">
				<h4 className="d-title-no-case">My Totals</h4>
			</div>
			<div className="reward-point-boxes">
				<CustomCards
					Icon={PointsToSendIcon}
					customCardsClassName="reward-bonus"
					cardColor="#14D6D3"
					pointAmount={rewardPointsBonus?.pointsToSend}
					cardTitle={'Total Points'}
				/>
				<CustomCards
					Icon={RewardIcon}
					cardColor="#e75da7"
					customCardsClassName="reward-bonus"
					pointAmount={rewardPointsBonus?.badges}
					cardTitle={rewardPointsBonus?.badges === 1 ? 'Badge' : 'Badges'}
				/>
				<CustomCards
					Icon={RecipientIcon}
					cardColor="#813ae2"
					customCardsClassName="reward-bonus"
					pointAmount={rewardPointsBonus?.shoutOuts}
					cardTitle={rewardPointsBonus?.shoutOuts === 1 ? 'Mention' : 'Mentions'}
				/>
			</div>
		</div>
	);
};

export default RewardPointsAndBonus;
