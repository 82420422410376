import { List } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { OnDeckReferralModal } from 'src/_shared/components/on-deck/general-referral-modal/index.js';
import { SearchComponent } from 'src/_shared/components/search/index.js';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	getSetErrorImageURL,
	logout,
	mapReferralStatus,
	ml,
	parse,
	searchOpenSearchNetwork,
	searchReferralData,
} from 'src/_shared/services/utils.js';
import ErinFilterSelect from '../_shared/components/ErinFilterSelectComponent.jsx';
import ReferralCardComponent from './referral-card/myReferralCardContainer';

class MyReferralsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			filteredReferrals: get(this.props, 'referrals', []),
			filteredStatuses: [],
			notifications: get(this.props, 'notifications', []),
			searchQuery: '',
			onDeckReferralModal: false,
			onDeckReferrals: this.getOnDeckReferrals(),
			height: 0,
			width: 0,
			loading: true,
			currencyRate: 1,
			currencySymbol: '$',
			referralBonuses: get(props, 'referralBonuses'),
			filteredJobs: [],
			filterByStatusOptions: [],
			searchedReferrals: [],
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		const filterByStatusOptions = this.getStatuses();
		const onDeckReferrals = this.getOnDeckReferrals();

		const errorImageURL = await getSetErrorImageURL(
			this.state?.company?.errorImage?.key
		);

		this.setState({
			errorImageSrc: errorImageURL,
			notifications: this.props.notifications,
			filteredReferrals: this.props.referrals,
			filterByStatusOptions,
			onDeckReferrals,
		});
		await this.getCurrencyRate(
			this.props.currentUser && this.props.currentUser.currency
				? this.props.currentUser.currency
				: 'USD'
		);
		await this.setQueryJobsToState('', 0);
		await this.setQueryReferralsToState('', 0);
	}

	componentDidUpdate(prevProps) {
		const {
			referralBonuses,
			notifications,
			onDeckContacts,
			onFetchMoreOnDeckContacts,
		} = this.props;
		const newState = {};
		let updateState = false;
		const webNotificationsNextToken = get(
			this.props,
			'webNotificationsNextToken'
		);
		const onDeckNextToken = get(this.props, 'onDeckNextToken');
		let callSearchAndFilter = false;

		if (this.props.onLoadMoreWebNotifications && webNotificationsNextToken) {
			if (prevProps.notifications.length === 0 && notifications.length > 0)
				callSearchAndFilter = true;
			this.props.onLoadMoreWebNotifications();
		} else if (prevProps.notifications !== notifications) {
			callSearchAndFilter = true;
		}

		if (prevProps.onDeckContacts !== onDeckContacts) {
			newState.onDeckReferrals = this.getOnDeckReferrals();
			updateState = true;
			if (onFetchMoreOnDeckContacts && onDeckNextToken)
				onFetchMoreOnDeckContacts();
		}

		if (prevProps.referralBonuses !== referralBonuses) {
			newState.referralBonuses = referralBonuses;
			updateState = true;
		}

		if (callSearchAndFilter === true) this.searchAndFilterReferrals();
		if (updateState) this.setState(newState);
	}

	setQueryReferralsToState = async (searchQuery = '', delay = 300) => {
		const { gdprReferrals = [], currentUser } = this.props;
		const { filteredStatuses = [] } = this.state;

		this.setState({ searchQuery });
		clearTimeout(this.timer);

		this.setState({ loading: true }, () => {
			this.timer = setTimeout(async () => {
				const parameters = {
					query: searchQuery,
					size: 300,
					role: currentUser?.role,
					currentUser: currentUser?.id,
					gdprReferrals,
					filters: {
						users: [currentUser?.id],
						companies: [currentUser?.company?.id],
						statuses: filteredStatuses,
					},
				};
				const response = await searchOpenSearchNetwork(
					parameters,
					'erin-referrals'
				);
				let searchedReferrals = get(response, 'data', []);
				searchedReferrals = this.searchAndFilterReferrals(searchedReferrals);

				let mergedReferralsData = searchedReferrals;
				if (this.props.referralsAdminNoteOwnershipEndDate) {
					mergedReferralsData = searchedReferrals.map((searchedReferral) => {
						const match = this.props.referralsAdminNoteOwnershipEndDate.find(
							(referralAdminNoteOwnershipEndDate) =>
								referralAdminNoteOwnershipEndDate.id === searchedReferral.id
						);
						return match
							? {
									...searchedReferral,
									adminNote: match.adminNote,
									ownershipEndDate: match.ownershipEndDate,
								}
							: searchedReferral;
					});
				}

				if (get(response, 'query') === get(this.state, 'searchQuery')) {
					this.setState({
						loading: false,
						referralTotals: get(response, 'totals', []),
						searchedReferrals: mergedReferralsData,
					});
				}
			}, delay);
		});
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.props.setCurrentCurrencyRate(rate);
			this.props.setCurrentCurrencySymbol(symbol);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	getOnDeckReferrals = (contacts) => {
		const { onDeckContacts, currentUser } = this.props;
		const referrals = contacts ? contacts : onDeckContacts;
		let newReferrals = [];
		for (const referral of referrals) {
			if (
				referral.userId === currentUser.id &&
				referral.referrals.length === 0 &&
				(referral.onDeckStatus === 'onDeck' ||
					referral.onDeckStatus === 'offDeck')
			) {
				if (referral.onDeckStatus === 'offDeck') referral.status = 'notHired';
				if (referral.onDeckStatus === 'onDeck') referral.status = 'referred';
				referral.contact = referral;
				referral.referralDate = referral.onDeckDate;
				if (!newReferrals.find((ref) => ref.id === referral.id)) {
					newReferrals.push(referral);
				}
			}
		}

		newReferrals = newReferrals.map((referral) => {
			return referral;
		});
		return newReferrals;
	};

	getStatuses = () => {
		const statusesData = ['referred', 'accepted', 'interviewing'];
		const company = get(this.props, 'currentUser.company');
		const customStages = get(company, 'stages');
		if (customStages) {
			const stages = parse(customStages).map(
				(stage) => Object.values(stage)[0]
			);
			if (stages.length > 0) {
				for (const status of stages) statusesData.push(status.trim());
			}
		}

		statusesData.push(
			'hired',
			'notHired',
			'declined',
			'noresponse',
			'transferred',
			'inactive',
			'ineligible'
		);
		const statusOptions = statusesData.map((status) => {
			return {
				label: mapReferralStatus(status, company),
				value: status,
			};
		});
		return statusOptions;
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryJobsToState = async (searchQuery = '', timeout = 800) => {
		return new Promise(async (resolve, reject) => {
			const {
				filteredDepartments = [],
				filteredBonuses = [],
				filteredDistance = 0,
				filterOpenStatus = 'open',
				lat,
				lng,
				filteredSubCompanies = [],
				filteredJobLevels = [],
			} = this.state;

			const filterCompanies = get(this.state, 'filteredCompanies', []).map(
				(company) => get(company, 'id')
			);

			const extendedCompaniesData = get(
				this.props,
				'currentUser.extendedCompaniesData',
				[]
			);

			const extendedCompanies = extendedCompaniesData.filter(
				(company) => get(company, 'disableExtendedUserJobsView') !== true
			);
			const isExtendedUser =
				get(this.props, 'currentUser.role') === 'extendedUser';

			const comps =
				extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
					? extendedCompanies
					: [get(this.props, 'currentUser.company')];

			const companies = comps.map((company) => get(company, 'id'));
			const filteredCompanies =
				filterCompanies.length > 0 ? filterCompanies : companies;

			const unit =
				get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
				'metric'
					? 'km'
					: 'mi';
			clearTimeout(this.timer);
			this.setState({ searchQuery });
			this.timer = setTimeout(async () => {
				const parameters = {
					query: get(this.state, 'searchQuery', ''),
					size: 300,
					coordinates: [{ lat, lng }],
					role: get(this.props, 'currentUser.role'),
					currentUser: get(this.props, 'currentUser.id'),
					filters: {
						bonuses: filteredBonuses,
						companies: filteredCompanies,
						departments: filteredDepartments,
						distance: filteredDistance,
						status: filterOpenStatus,
						subCompanies: filteredSubCompanies,
						jobLevels: filteredJobLevels,
					},
					unit,
				};
				const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
				if (get(response, 'query') === get(this.state, 'searchQuery')) {
					const searchedJobs = get(response, 'data', []);
					if (searchedJobs) {
						const filteredJobs = searchedJobs.filter(
							(item) => item.isGeneralReferral === true
						);
						this.setState({
							filteredJobs,
						});
					}

					this.setState({ searchedJobs });
				}

				resolve();
			}, timeout);
		});
	};

	searchAndFilterReferrals = (
		referrals = [],
		searchQuery = this.state.searchQuery,
		filteredStatuses = this.state.filteredStatuses
	) => {
		const { currentUser, notifications } = this.props;
		const gdprReferrals = notifications.filter(
			(notification) =>
				notification.type === 'gdprReferralCreated' &&
				get(notification, 'user.id') === get(currentUser, 'id') &&
				get(notification, 'status') !== 'accepted'
		);
		let filteredReferrals = [];
		if (referrals && this.state.onDeckReferrals) {
			filteredReferrals = [
				...referrals,
				...this.state.onDeckReferrals,
				...gdprReferrals,
			];
		} else if (this.state.onDeckReferrals) {
			filteredReferrals = [...this.state.onDeckReferrals, ...gdprReferrals];
		} else if (referrals) {
			filteredReferrals = [referrals, ...gdprReferrals];
		}

		const searchedReferrals = searchReferralData(
			['firstName', 'lastName'],
			searchQuery,
			filteredReferrals
		);

		let sortedReferrals = _.sortBy(searchedReferrals, function (referral) {
			return referral.referralDate;
		})
			.filter((referral) => referral.referralType !== 'self')
			.reverse();
		if (filteredStatuses.length > 0) {
			sortedReferrals = sortedReferrals.filter((referral) => {
				if (
					get(referral, 'status') === 'interviewing' &&
					get(referral, 'customStatus')
				) {
					if (filteredStatuses.includes(referral.customStatus)) return true;
				} else if (filteredStatuses.includes(referral.status)) return true;
				return false;
			});
		}

		return sortedReferrals;
	};

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	render() {
		const { allMultiLingualData, currentUser, companyData } = this.props;
		if (!get(currentUser, 'company')) return <Spinner />;
		const contactIncentiveBonus = get(
			currentUser,
			'company.contactIncentiveBonus'
		);
		const {
			company,
			contacts,
			errorImageSrc,
			searchQuery,
			loading,
			currencyRate,
			currencySymbol,
			filteredStatuses,
			filterByStatusOptions,
			filteredJobs,
			searchedReferrals,
		} = this.state;
		const whiteLabel = get(this.state, 'company.whiteLabel');

		const enableLead = get(currentUser, 'company.enableLead');
		const parsedOwnershipSettings = JSON.parse(
			get(companyData, 'ownershipSettings', '{}')
		);

		const { referralBonuses } = this.props;

		return (
			<main>
				<div className="page-top-filter">
					<div className="filter-wrap">
						<SearchComponent
							searchQuery={searchQuery}
							setQueryToState={this.setQueryReferralsToState}
							placeholder={ml('Search', currentUser, allMultiLingualData)}
						/>
						<ErinFilterSelect
							options={filterByStatusOptions}
							placeholder={ml('Status', currentUser, allMultiLingualData)}
							appliedFilters={filteredStatuses}
							onChange={(selectedFilters) =>
								this.setState({ filteredStatuses: selectedFilters }, () =>
									this.setQueryReferralsToState(this.state.searchQuery, 0)
								)
							}
						/>
					</div>
					{enableLead && (
						<OnDeckReferralModal
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							contacts={contacts}
							jobData={this.props.job}
							submitGeneralReferralText={ml(
								'Submit Referral Lead',
								currentUser,
								allMultiLingualData
							)}
							referSomeoneText={ml(
								'Refer Someone',
								currentUser,
								allMultiLingualData
							)}
							doYouKnowText={ml(
								'Do you know someone who would thrive at',
								currentUser,
								allMultiLingualData
							)}
							submitThemText={ml(
								'Submit them to be considered for new opportunities.',
								currentUser,
								allMultiLingualData
							)}
							enterReferralText={ml(
								"Enter a referral and we'll send them information to apply.",
								currentUser,
								allMultiLingualData
							)}
							enterReferralInformationText={ml(
								'Enter Referral Information',
								currentUser,
								allMultiLingualData
							)}
							firstNameText={ml('First Name', currentUser, allMultiLingualData)}
							lastNameText={ml('Last Name', currentUser, allMultiLingualData)}
							emailText={ml('Email Address', currentUser, allMultiLingualData)}
							textPlaceHolderText={ml('Text', currentUser, allMultiLingualData)}
							orText={ml('or', currentUser, allMultiLingualData)}
							clickHereText={ml('click here', currentUser, allMultiLingualData)}
							toAddExistingContactText={ml(
								'to add an existing contact',
								currentUser,
								allMultiLingualData
							)}
							includeMessageToContactText={ml(
								'Include a message to your contact',
								currentUser,
								allMultiLingualData
							)}
							optionalText={`(${ml('optional', currentUser, allMultiLingualData)})`}
							requiredText={`(${ml('required', currentUser, allMultiLingualData)})`}
							personalizeMessageText={ml(
								'Personalize the message to your referral',
								currentUser,
								allMultiLingualData
							)}
							messageHiringContactText={ml(
								'Message the Hiring Contact',
								currentUser,
								allMultiLingualData
							)}
							howKnowThemText={ml(
								'How do you know them, why are they a good fit, etc.',
								currentUser,
								allMultiLingualData
							)}
							clickHereResumeText={ml(
								'Click here',
								currentUser,
								allMultiLingualData
							)}
							attachResumeText={ml(
								'to attach a resume',
								currentUser,
								allMultiLingualData
							)}
							submitReferralText={ml(
								'Submit Referral',
								currentUser,
								allMultiLingualData
							)}
							filteredJobs={filteredJobs}
							onDeckContacts={this.props.onDeckContacts}
							onCreateContact={this.props.onCreateContact}
							onUpdateContact={this.props.onUpdateContact}
						/>
					)}
				</div>
				<div>
					{loading ? (
						<Spinner company={company} />
					) : searchedReferrals?.length > 0 ? (
						<List
							className="my-progressbar"
							grid={{ gutter: 14, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}
							dataSource={searchedReferrals}
							itemLayout="horizontal"
							pagination={{ pageSize: 12, showSizeChanger: false }}
							renderItem={(referral) => (
								<List.Item>
									<ReferralCardComponent
										allMultiLingualData={allMultiLingualData}
										referral={referral}
										currentUser={currentUser}
										bonuses={referralBonuses}
										ownershipSettings={parsedOwnershipSettings}
									/>
								</List.Item>
							)}
						/>
					) : (
						<div className="no-content">
							{whiteLabel ? (
								<img
									className="no-content-icon"
									src={errorImageSrc}
									alt="error image"
								/>
							) : (
								<img
									className="no-content-icon"
									alt="erin-logo"
									src={fileIcon}
								/>
							)}
							<p className="no-content-text">
								{searchQuery.length > 0 || filteredStatuses.length > 0
									? 'No referrals matching your request were found.'
									: "You haven't made any referrals yet."}
							</p>
						</div>
					)}
				</div>
			</main>
		);
	}
}
export default withApollo(MyReferralsComponent);
